//<МИКСИНЫ>==============================================================================================

//Адаптивное свойство
@mixin adaptiv-value($indentType, $pcSize, $mobSize, $maxSize) {
	$addSize: $pcSize - $mobSize;
	@if $maxSize==1 {
		//Только если меньше контейнера
		#{$indentType}: $pcSize + px;
		@media (max-width: #{$maxWidthContainer + px}) {
			#{$indentType}: calc(#{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidthContainer - 320}));
		}
	} @else if $maxSize==2 {
		//Только если больше контейнера
		#{$indentType}: $pcSize + px;
		@media (min-width: #{$maxWidthContainer + px}) {
			#{$indentType}: calc(#{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth - 320}));
		}
	} @else {
		//Всегда
		#{$indentType}: calc(#{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth - 320}));
	}
}

//</МИКСИНЫ>===============================================================================================
:root {
	// Цвета
	--color-white: #fff;
	--color-black: #1a1a1a;
	--color-black-2: #2f2f2f;
	--color-blue: #2b52a0;
	--color-grey: #F6F1E8;
	--color-grey-2: #99999b;
	--color-yellow: #fec840;


	// Размер шрифта


	// Начертание


	// transition
	--transition-normal: all 0.3s ease 0s;

	// Радиус

}

//<ПЕРЕМЕННЫЕ>===========================================================================================================
$gutters: 15;
$minWidth: 320px;
$maxWidth: 1440;
$maxWidthContainer: 1170;
$max: 1399.98;
$md1: 1199.98;
$md2: 991.98;
$md3: 767.98;
$md4: 575.98;
$md5: 479.98;


.header {
	background: var(--color-white);
	padding-top: 45px;
	// .header__menu
	&__menu {
	}
}

.btn-blue {
	background: var(--color-blue);
	box-shadow: 0 10px 25px 0 rgba(43, 82, 160, 0.44);
	font-size: 17px;
	font-weight: 500;
	color: var(--color-white);
	line-height: 30px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	column-gap: 14px;
	border-radius: 100px;
	padding: 9px 34px;
	transition: var(--transition-normal);

	&:hover {
		color: var(--color-white);
		background: #3264cb;
	}

	svg {
		width: 30px;
		height: 30px;
		fill: var(--color-yellow);
	}
}

.header-btn-mob {
	padding: 6px 34px;
	box-shadow: none;
	position: relative;
	z-index: 6;
	@media (max-width: $md4+px) {
		padding: 6px 20px;
		font-size: 12px;
		column-gap: 6px;
		svg {
			width: 17px;
			height: 17px;
			fill: var(--color-yellow);
		}
	}
	@media (min-width: $md2+px) {
		display: none;
	}
}

.btn-more-details {
	cursor: pointer;
	height: 37px;
	background-color: #f7f1e7;
	white-space: nowrap;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 14px;
	font-weight: 500;
	column-gap: 6px;
	color: var(--color-black-2);
	border-radius: 100px;
	padding: 10px 24px;
	transition: var(--transition-normal);
	border: 2px solid transparent;

	&:hover {
		border: 2px solid var(--color-yellow);
		color: var(--color-yellow);
		background: var(--color-white);
	}

	svg {
		transition: left 0.3s;
		fill: currentColor;
		width: 12px;
		height: 12px;
		position: relative;
		top: -1px;
	}
}

.header-top {
	background: var(--color-blue);
	min-height: 45px;
	padding: 5px 0;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 3;
	// .header-top__row
	&__row {
		display: flex;
		align-items: center;
		padding: 0 94px;
		margin: 0 -15px;
	}

	.header-top__col-1 {
		flex: 1 0 0%;
		width: 100%;
		max-width: 100%;
		@media (min-width: $md2+px) {
			display: none;
		}
	}

	.header-top__col-2 {
		flex: 1 0 0%;
		width: 100%;
		max-width: 100%;
	}

	.header-top__col-3 {
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
}

.menu {
	// .menu__body
	&__body {
	}
}

.main-menu {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	column-gap: 18px;
	row-gap: 2px;
	list-style: none;

	& > li {
		position: relative;
		@media (min-width: $md2+px) {
			&:hover {
				& > .sub-menu {
					opacity: 1;
					visibility: visible;
					pointer-events: auto;
				}
			}
		}

		a {
			color: var(--color-white);
			display: inline-flex;
			align-items: center;
			column-gap: 2px;
			font-size: 14px;
			transition: var(--transition-normal);

			&:hover {
				color: var(--color-yellow);
				text-decoration: underline;
			}

			svg {
				width: 20px;
				height: 20px;
				fill: currentColor;
				transition: var(--transition-normal);
			}

			&.active {
				color: var(--color-yellow);

				&:hover {
					svg {
						color: var(--color-yellow);
					}
				}

				svg {
					color: var(--color-white);
				}
			}
		}

		.sub-menu {
			opacity: 0;
			visibility: hidden;
			pointer-events: none;
			position: absolute;
			top: calc(100%);
			left: -26px;
			z-index: 2;
			min-width: 150px;
			padding-top: 20px;

			ul {
				list-style: none;
				padding: 23px 26px;
				position: relative;
				background: var(--color-blue);

			}

			li {
				&:not(:last-child) {
					margin-bottom: 15px;
				}

				a {
					font-size: 14px;
					color: var(--color-white);
					transition: var(--transition-normal);
					white-space: nowrap;

					&.active {
						text-decoration: underline;
						color: var(--color-yellow);
					}

					&:hover {
						text-decoration: underline;
						color: var(--color-yellow);
					}

				}
			}
		}
	}
}

.cabinet-header {
	display: flex;
	align-items: center;
	column-gap: 6px;

	&:hover {
		.cabinet-header__icon {
			border: 2px solid var(--color-yellow);
		}
	}

	// .cabinet-header__icon
	&__icon {
		width: 31px;
		height: 31px;
		border-radius: 50%;
		background-color: #22468e;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: var(--transition-normal);
		border: 2px solid transparent;

		svg {
			width: 18px;
			height: 18px;
			fill: var(--color-yellow);
		}
	}

	// .cabinet-header__text
	&__text {
		font-size: 15px;
		color: var(--color-white);
	}
}

.cabinet-header-mob {
	padding: 14px 0;
	column-gap: 12px;
	display: flex;
	@media (min-width: $md2+px) {
		display: none;
	}

	.cabinet-header__text {
		font-size: 18px;
	}
}

.search-header {
	margin-left: auto;
	position: relative;

	&:hover {
		.search-header__icon {
			border: 2px solid var(--color-white);
		}
	}

	// .search-header__icon
	&__icon {
		height: 31px;
		width: 31px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #22468e;
		cursor: pointer;
		border: 2px solid transparent;
		transition: var(--transition-normal);
		position: relative;
		z-index: 3;

		svg {
			width: 15px;
			height: 15px;
			fill: var(--color-white);
		}
	}

	// .search-header__body
	&__body {
		position: absolute;
		top: 0;
		right: -1px;
		z-index: 2;
		width: 300px;
		background: var(--color-white);
		border-radius: 100px;
		transform: translateX(100%);
		transition: var(--transition-normal);
		background-color: #22468e;
		opacity: 0;
		visibility: hidden;

		&.open {
			transform: translateX(0);
			opacity: 1;
			visibility: visible;
			z-index: 4;
		}
	}
}

.form-search {
	display: flex;
	align-items: center;

	button {
		width: 31px;
		height: 31px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #22468e;
		cursor: pointer;
		border: 2px solid transparent;
		transition: var(--transition-normal);

		svg {
			width: 15px;
			height: 15px;
			fill: var(--color-white);
		}
	}
}

.icon-menu {
	width: 42px;
	height: 42px;
	border-radius: 50%;
	background: var(--color-white);
	border: 5px solid var(--color-yellow);
	cursor: pointer;
	position: relative;
	z-index: 6;
	@media (min-width: $md2+px) {
		display: none;
	}

	span {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transform-origin: 0 0;
		width: 19px;
		height: 2px;
		background: var(--color-blue);
		transition: all 0.2s ease 0s;

		&:last-child {
			top: calc(50% - 5px);
		}

		&:first-child {
			top: calc(50% + 5px);
		}
	}

	&.open {
		span {
			transform: scale(0);

			&:first-child {
				transform: rotate(45deg) translate(-50%, -50%);
				top: 50%;
			}

			&:last-child {
				transform: rotate(-45deg) translate(-50%, -50%);
				top: 50%;
			}
		}
	}
}

.header-actions {
	display: flex;
	align-items: center;
	column-gap: 30px;
}

.btn-blue {
	cursor: pointer;
	outline: none;
	border: none;
	background: var(--color-blue);
	box-shadow: 0 10px 25px 0 rgba(43, 82, 160, 0.44);
	font-size: 17px;
	font-weight: 500;
	color: var(--color-white);
	line-height: 30px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	column-gap: 14px;
	border-radius: 100px;
	padding: 9px 34px;
	transition: var(--transition-normal);

	&:hover {
		color: var(--color-white);
		background: #3264cb;
	}

	svg {
		width: 30px;
		height: 30px;
		fill: var(--color-yellow);
	}

	&.btn-yellow {
		background: var(--color-yellow);
		color: var(--color-black);
		box-shadow: 0px 10px 13px 0px rgba(0, 0, 0, 0.18);

		&:hover {
			color: var(--color-black);
			box-shadow: 0 3px 15px 0 rgba(43, 82, 160, 0.44);
		}
	}
}

.main-popup {
	display: none;
	padding: 40px 60px;
	background: var(--color-white);
	max-width: 380px;
	@media (max-width: $md5+px) {
		padding: 40px 25px;
	}

	.fancybox-close-small {
		width: 46px;
		height: 46px;
		border-radius: 0;
		top: 0;
		background: var(--color-yellow);
		display: flex;
		justify-content: center;
		align-items: center;

		&:hover {
			&:after {
				background: transparent;
			}
		}

		&:after {
			font-size: 38px;
			color: var(--color-white);
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

		}

		&.is-close {
			top: 0;
		}

		@media (max-width: $md5+px) {
			width: 36px;
			height: 36px;
		}

		svg {
			filter: none;
		}

	}

	// .main-popup__title
	&__title {
		font-size: 22px;
		font-weight: 500;
		color: var(--color-blue);
		margin: 0 0 34px 0;
	}

	&.fancybox__content :focus:not(.carousel__button.is-close) {
		outline: none !important;

		.form-control {
			&:focus {
				box-shadow: 0 0 0 0.25rem rgba(43, 82, 160, 0.25);
			}
		}
	}

	.form-group .form-control {
		max-width: 100%;
	}
}

.fancybox__content {
	padding: 40px 60px;
	background: var(--color-white);
}

.form-label {
	margin-bottom: 0.5rem;
	display: inline-block;
}

.main-form {
	// .main-form__link
	&__link {
		font-size: 12px;
		text-decoration: underline;
		color: #7d7d7d;

		&:hover {
			color: #7d7d7d;
			text-decoration: none;
		}
	}

	button {
		display: flex;
		@media (max-width: $md4+px) {
			width: 100%;
		}
	}

	// .main-form__text
	&__text {
		font-size: 16px;
		color: #494949;
	}

	a {
		color: var(--color-blue);
		transition: var(--transition-normal);

		&:hover {
			color: var(--color-yellow);
		}

		&[type="tel"] {
			white-space: nowrap;
		}
	}

	// .main-form__bottom
	&__bottom {
		margin: 30px -60px -40px;
		background-color: #ededed;
		padding: 14px 60px 17px;
		@media (max-width: $md5+px) {
			margin: 30px -25px -40px;
			padding: 14px 25px 17px;
		}

		a {
			display: flex;
			align-items: center;
			column-gap: 24px;
			font-size: 16px;
			text-decoration: underline;
			color: #5a5a5a;
		}
	}
}

.form-group {
	.form-label {
		font-size: 14px;
		color: var(--color-grey-2);
		@media (max-width: $md3+px) {
			font-size: 13px;
		}
	}

	.form-control {
		width: 100%;
		max-width: 312px;
		height: 51px;
		background-color: #ededed;
		border-radius: 100px;
		padding: 5px 20px;
		font-size: 16px;
		color: var(--color-black);
		border: none;

		&:focus {
			color: var(--color-black);
			background-color: #ededed;
			box-shadow: 0 0 0 0.25rem rgba(43, 82, 160, 0.25);
			border-color: transparent;
		}

		@media (max-width: $md4+px) {
			max-width: 100%;
		}
	}

	.form-check {
		cursor: pointer;
		display: block;
		min-height: auto;
		padding-left: 0;
		margin-bottom: 0;
		position: relative;

		.form-check-input {
			display: block;
			height: 0;
			width: 0;
			opacity: 0;
			position: absolute;
			top: 50%;
			left: 50%;
			margin: 0;

			&:checked + span:after {
				background: url("../img/check.png") 5px 1px no-repeat;
				background-size: 19px 13px;
				opacity: 1;
				visibility: visible;
			}
		}

		span {
			width: 28px;
			height: 28px;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;

			&:after {
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				z-index: 1;
				top: 4px;
				left: 3px;
				opacity: 0;
				visibility: hidden;
			}

			&:before {
				content: '';
				display: block;
				width: calc(100% - 3px);
				height: calc(100% - 3px);
				border-radius: 50%;
				background-color: rgba(254, 200, 64, 0.0);
				border: 2px solid #d5d5d5;
			}
		}
	}

	.form-error {
		font-size: 12px;
		font-weight: 500;
		color: #fc0e1c;
	}

	p {
		font-size: 11px;
		color: var(--color-grey-2);
		margin-bottom: 0;
	}

	a {
		font-size: 11px;
		color: var(--color-grey-2);
		text-decoration: underline;
		transition: var(--transition-normal);

		&:hover {
			color: var(--color-grey-2);
			text-decoration: none;
		}
	}
}

.mb-4 {
	margin-bottom: 1.5rem !important;
}

.mt-4 {
	margin-top: 1.5rem !important;
}

.w-100 {
	width: 100% !important;
}

.text-end {
	text-align: right !important;
}

.d-block {
	display: block !important;
}